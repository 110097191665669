import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCopyright } from '@fortawesome/pro-light-svg-icons';
import { faChevronDown, faUser as farUser } from '@fortawesome/pro-regular-svg-icons';
import { faShoppingCart, faUser as fasUser } from '@fortawesome/pro-solid-svg-icons';
import { faBars, faXmark } from '@fortawesome/sharp-solid-svg-icons';
import { AdminModule, AuthModule, NotificationModule, MediaSizeDetectorModule } from '@motivforce/mx-library-angular';
import { NgbDropdownModule, NgbOffcanvasModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'src/app/shared/shared.module';

import { FooterComponent } from './components/footer/footer.component';
import { FullLayoutComponent } from './components/full-layout/full-layout.component';
import { HeaderComponent } from './components/header/header.component';
import { MainMenuComponent } from './components/main-menu/main-menu.component';
import { ProfileMenuComponent } from './components/profile-menu/profile-menu.component';

@NgModule({
  declarations: [FullLayoutComponent, HeaderComponent, FooterComponent, MainMenuComponent, ProfileMenuComponent],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    FontAwesomeModule,
    AuthModule,
    NgbDropdownModule,
    AdminModule,
    NotificationModule,
    NgbOffcanvasModule,
    TranslateModule,
    MediaSizeDetectorModule,
  ],
  exports: [FullLayoutComponent],
})
export class LayoutModule {
  constructor(private iconlibrary: FaIconLibrary) {
    this.iconlibrary.addIcons(faShoppingCart, farUser, fasUser, faBars, faXmark, faChevronDown, faCopyright);
  }
}
