<footer class="footer">
  <div class="h-100 container">
    <div class="h-100 row">
      <div class="col-12">
        <div class="h-100 gap-5 d-flex justify-content-start align-items-center">
          <div class="footer-item">
            <a [href]="privacyUrl" target="_blank">Nutanix Privacy Statement</a>
          </div>
          <div class="footer-item">
            <a [href]="termsUrl" target="_blank">T&C's</a>
          </div>
          <div class="footer-item">
            <a [href]="'mailto:' + supportEmail" target="_blank">Contact Us</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
