import { Location } from '@angular/common';
import { Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import {
  ContentStoreService,
  MxLoggerService,
  AuthStoreService,
  NavMenu,
  MediaSizeDetectorStoreService,
  MediaScreenSize,
} from '@motivforce/mx-library-angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { delay, filter } from 'rxjs/operators';
import { PromotionStoreService } from 'src/app/core/store/promotion-store.service';
import { environment } from 'src/environments/environment';

@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @ViewChild('header', { static: true }) headerElement: ElementRef<HTMLElement>;

  headerFixed = environment.headerFixed;
  headerTransparent = true;

  authenticatedUser$ = this.authStore.authenticatedUser$.pipe(untilDestroyed(this));

  headerMenu: NavMenu;

  qow$ = this.promotionStore.currentQow$;

  mediaSize: MediaScreenSize;

  constructor(
    private authStore: AuthStoreService,
    private renderer: Renderer2,
    private contentStore: ContentStoreService,
    private mxLogger: MxLoggerService,
    private promotionStore: PromotionStoreService,
    private location: Location,
    private router: Router,
    private mediaSizeDetectorStore: MediaSizeDetectorStoreService
  ) {}

  ngOnInit(): void {
    this.contentStore.headerMenu$.pipe(filter(Boolean)).subscribe((headerMenu) => {
      this.mxLogger.debug('HeaderComponent', 'ngOnInit() headerMenu=', headerMenu);

      this.headerMenu = headerMenu;
    });

    this.router.events
      .pipe(
        filter(
          (event) =>
            event instanceof NavigationStart ||
            event instanceof NavigationEnd ||
            event instanceof NavigationCancel ||
            event instanceof NavigationError
        )
      )
      .subscribe(() => {
        this.updateHeader();
      });

    this.mediaSizeDetectorStore.mediaSize$.pipe(filter(Boolean), delay(1)).subscribe((mediaSize) => {
      this.mediaSize = mediaSize;
      this.updateHeader();
    });

    this.updateHeader();
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(): void {
    if (environment.headerFixed) {
      if (window.scrollY > 0) {
        this.renderer.addClass(this.headerElement.nativeElement, 'scrolled');
      } else {
        this.renderer.removeClass(this.headerElement.nativeElement, 'scrolled');
      }
    }
  }

  private updateHeader(): void {
    this.headerTransparent =
      this.location.path().includes('registration') &&
      this.mediaSize !== MediaScreenSize.XS &&
      this.mediaSize !== MediaScreenSize.SM &&
      this.mediaSize !== MediaScreenSize.MD &&
      this.mediaSize !== MediaScreenSize.LG;

    this.headerFixed = !this.headerTransparent;
  }
}
