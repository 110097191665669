import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-public-main-layout',
  templateUrl: './public-main-layout.component.html',
  styleUrls: ['./public-main-layout.component.scss'],
})
export class PublicMainLayoutComponent {
  @Input() background: string;

  olympicsWhiteDesktopLogo =
    'https://mmicdn.s3.eu-central-1.amazonaws.com/NutanixRaceToOlympics/Logo/Race-to-the-Olympics-logo_Desktop_72dpi.png';

  olympicsWhiteTabletLogo =
    'https://mmicdn.s3.eu-central-1.amazonaws.com/NutanixRaceToOlympics/Logo/Race-to-the-Olympics-logo_Tablet_72dpi.png';

  olympicsWhiteMobileLogo =
    'https://mmicdn.s3.eu-central-1.amazonaws.com/NutanixRaceToOlympics/Logo/Race-to-the-Olympics-logo_Mobile_72dpi.png';
}
