<div class="w-100 h-100 pt-5 container-fluid background" [style.background-image]="'url(' + background + ')'">
  <div class="mt-5 pt-5 row">
    <div class="col-12">
      <div class="mt-5 mt-xl-0 w-100 d-flex justify-content-center">
        <img class="d-none d-xl-block" [src]="olympicsWhiteDesktopLogo" />
        <img class="d-none d-md-block d-xl-none" [src]="olympicsWhiteTabletLogo" />
        <img class="d-md-none" [src]="olympicsWhiteMobileLogo" />
      </div>
    </div>
    <ng-content></ng-content>
  </div>
</div>
