import { Component, OnInit } from '@angular/core';
import { ContentStoreService } from '@motivforce/mx-library-angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  year: string;

  termsUrl =
    'https://mmicdn.s3.eu-central-1.amazonaws.com/NutanixRaceToOlympics/T%26C+-+Nutanix_FY25_Race_to_Olympics_v1.pdf';

  privacyUrl = 'https://www.nutanix.com/legal/privacy-statement';
  supportEmail: string;

  constructor(private contentStore: ContentStoreService) {}

  ngOnInit(): void {
    this.year = new Date().getFullYear().toString();

    this.contentStore.siteSettings$.pipe(untilDestroyed(this), filter(Boolean)).subscribe((settings) => {
      const { siteSettings } = settings;

      this.supportEmail = (siteSettings as any).supportEmail;
    });
  }
}
